import React from 'react'
import './Details.css'

const Details = (props) => {
  return (
    <p className='details'>{props.details}</p>
    
  )
}

export default Details